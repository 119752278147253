document.addEventListener('touchstart', () => {}, { passive: true });
document.addEventListener('touchmove', () => {}, { passive: true });
document.addEventListener('wheel', () => {}, { passive: true });
document.addEventListener('mousewheel', () => {}, { passive: true });

document.addEventListener("DOMContentLoaded", function(event) {
    const mqSM = window.matchMedia('(max-width: 575px)');
    const mqMD = window.matchMedia('(min-width: 576px) and (max-width: 767px)');
    const mqLG = window.matchMedia('(min-width: 768px) and (max-width: 991px)');
    const mqXL = window.matchMedia('(min-width: 992px) and (max-width: 1199px)');
    const mqCustomXL = window.matchMedia('(min-width: 1200px) and (max-width: 1365px)');
    const mqXXL = window.matchMedia('(min-width: 1366px) and (max-width: 1399px)');
    const mqXXXL = window.matchMedia('(min-width: 1400px)');

    let html = document.querySelector('html');
    let body = document.querySelector('body');
    let wpadminbar = document.querySelector('#wpadminbar');
    let header = document.querySelector('.c-main-header');
    let mainHeaderHamburger = document.querySelector('.c-main-header__hamburger');
    let menu = document.querySelector('.c-header-menu');
    let menuSection = document.querySelector('.c-header-menu__list');
    let menuItems = document.querySelectorAll('.c-header-menu__list-item');
    let tof = document.querySelector('.c-tof');
    let content = document.querySelectorAll('.c-standard-section__content');
    let navigationList = document.querySelector('.c-tof__list');
    const wpadmin = body.classList.contains('wp-admin');
    let menuScroll;
    let mainHeaderHeight;
    let wpadminbarHeight;
    /*******************************************/
    /* POST SLIDERS */
    let newestPostsSliders = document.querySelectorAll('.c-newest-posts-se__slider');
    let referencesPostsSliders = document.querySelectorAll('.c-references-se__slider');
    let similarPostsSliders = document.querySelectorAll('.c-similar-posts-se__slider');

    let swiperPostsSliders = [];
    const configPostsSliders = {
        slidesPerView: 1,
        watchSlidesProgress: true
    };
    let paginationPostsSliders = false;
    let navigationPostsSliders = false;

    if (wordpress.post_slider_speed !== null) {
        configPostsSliders['speed'] = parseInt(wordpress.post_slider_speed);
    } else {
        configPostsSliders['speed'] = 300;
    }
    if (wordpress.post_slider_autoplay !== null) {
        if (wordpress.post_slider_autoplay) {
            if (wordpress.post_slider_delay !== null) {
                configPostsSliders['autoplay'] = {
                    delay: parseInt(wordpress.post_slider_delay),
                    disableOnInteraction: false
                };
            } else {
                configPostsSliders['autoplay'] = {
                    delay: 5000,
                    disableOnInteraction: false
                };
            }
        } else {
            configPostsSliders['autoplay'] = false;
        }
    } else {
        configPostsSliders['autoplay'] = {
            delay: 5000,
            disableOnInteraction: false
        };
    }
    if (wordpress.post_slider_pagination !== null) {
        if (wordpress.post_slider_pagination) {
            paginationPostsSliders = true;
        } else {
            paginationPostsSliders = false;
        }
    } else {
        paginationPostsSliders = false;
    }
    if (wordpress.post_slider_navigation !== null) {
        if (wordpress.post_slider_navigation) {
            navigationPostsSliders = true;
        } else {
            navigationPostsSliders = false;
        }
    } else {
        navigationPostsSliders = false;
    }
    /* END RESIZE FUNCTIONS */
    /*******************************************/
    function debounce(func, wait, immediate) {
        /* debounce */
        return function() {
            let timeout;
            const context = this,
                args = arguments;
            const later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
    /*******************************************/
    /* SLIDE UP, SLIDE DOWN, SLIDE TOGGLE */
    function slideUp(target, duration = 200) {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        window.setTimeout(() => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    function slideDown(target, duration = 200) {
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;
        if (display === 'none')
            display = 'flex';
        target.style.display = display;
        const height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout(() => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    }

    function slideToggle(target, duration = 200) {
        if (window.getComputedStyle(target).display === 'none') {
            return slideDown(target, duration);
        } else {
            return slideUp(target, duration);
        }
    }

    const setSlideUp = debounce(function(target, duration = 200) {
        slideUp(target, duration);
    }, 200);
    const setSlideDown = debounce(function(target, duration = 200) {
        slideDown(target, duration);
    }, 200);
    const setSlideToggle = debounce(function(target, duration = 200) {
        slideToggle(target, duration);
    }, 200);
    /**********************************************/
    const scrollToSmoothly = (pos, time) => {
        const currentPos = window.pageYOffset;
        let start = null;
        if (time == null) time = 500;
        pos = +pos, time = +time;
        history.pushState("", document.title, window.location.pathname);
        window.requestAnimationFrame(function step(currentTime) {
            start = !start ? currentTime : start;
            const progress = currentTime - start;
            if (currentPos < pos) {
                window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
            } else {
                window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
            }
            if (progress < time) {
                window.requestAnimationFrame(step);
            } else {
                window.scrollTo(0, pos);
            }
        });
    };
    /*******************************************/
    function makeNavigationListElements(selector) { //tof
        selector.forEach(function(e) {
            e.forEach(function(x) {
                const aID = x.id;
                const aText = x.textContent;
                const liElement = document.createElement('li');
                liElement.className = 'c-tof__list-item';
                const aElement = document.createElement('a');
                aElement.className = 'c-tof__list-item-link';
                aElement.setAttribute('href', '#' + aID);
                aElement.textContent = aText;
                aElement.onclick = function(e) {
                    e.preventDefault();
                    const hash = e.currentTarget.getAttribute("href").split('#');
                    const scrollTarget = document.querySelector('#' + hash[1]);
                    scrollToSmoothly(scrollTarget.offsetTop - mainHeaderHeight, 1000);
                };
                liElement.appendChild(aElement);
                navigationList.append(liElement);
            });
        });
    }
    /*******************************************/
    function initTOF() {
        const pIds = [];
        const aIds = [];
        const h1Ids = [];
        const h2Ids = [];
        const h3Ids = [];
        const h4Ids = [];
        const h5Ids = [];
        const h6Ids = [];
        content.forEach(function(e) {
            if (e.querySelectorAll('p[id]:not([id^="block-"])').length > 0) {
                pIds.push(e.querySelector('p[id]:not([id^="block-"])'));
            }
            if (e.querySelectorAll('a[id]:not([id^="block-"])').length > 0) {
                aIds.push(e.querySelectorAll('a[id]:not([id^="block-"])'));
            }
            if (e.querySelectorAll('h1[id]:not([id^="block-"])').length > 0) {
                h1Ids.push(e.querySelectorAll('h1[id]:not([id^="block-"])'));
            }
            if (e.querySelectorAll('h2[id]:not([id^="block-"])').length > 0) {
                h2Ids.push(e.querySelectorAll('h2[id]:not([id^="block-"])'));
            }
            if (e.querySelectorAll('h3[id]:not([id^="block-"])').length > 0) {
                h3Ids.push(e.querySelectorAll('h3[id]:not([id^="block-"])'));
            }
            if (e.querySelectorAll('h4[id]:not([id^="block-"])').length > 0) {
                h4Ids.push(e.querySelectorAll('h4[id]:not([id^="block-"])'));
            }
            if (e.querySelectorAll('h5[id]:not([id^="block-"])').length > 0) {
                h5Ids.push(e.querySelectorAll('h5[id]:not([id^="block-"])'));
            }
            if (e.querySelectorAll('h6[id]:not([id^="block-"])').length > 0) {
                h6Ids.push(e.querySelectorAll('h6[id]:not([id^="block-"])'));
            }
        });
        if (pIds.length !== 0) {
            makeNavigationListElements(pIds);
        }
        if (aIds.length !== 0) {
            makeNavigationListElements(aIds);
        }
        if (h1Ids.length !== 0) {
            makeNavigationListElements(h1Ids);
        }
        if (h2Ids.length !== 0) {
            makeNavigationListElements(h2Ids);
        }
        if (h3Ids.length !== 0) {
            makeNavigationListElements(h3Ids);
        }
        if (h4Ids.length !== 0) {
            makeNavigationListElements(h4Ids);
        }
        if (h5Ids.length !== 0) {
            makeNavigationListElements(h5Ids);
        }
        if (h6Ids.length !== 0) {
            makeNavigationListElements(h6Ids);
        }
    }

    function toggleMenu() {
        //body.classList.toggle('disable-scroll');
        menu.classList.toggle('c-header-menu--is-active');
        setSlideToggle(menu);
        mainHeaderHamburger.classList.toggle('c-main-header__hamburger--is-open');
    }

    function hideSubMenu(hasChildren) {
        const subMenu = hasChildren.querySelector('.c-header-menu__menu-subs');
        const displaySubMenu = window.getComputedStyle(subMenu).display;
        if (displaySubMenu == 'flex') {
            setSlideUp(subMenu);
            arrowDownIco = subMenu.parentElement.querySelector('.c-header-menu__list-item-arrow-down-ico');
            arrowDownIco.classList.remove('c-header-menu__list-item-arrow-down-ico--rotate');
        }
    }

    function showSubMenu(hasChildren) {
        const subMenuOthers = document.querySelectorAll('.c-header-menu__menu-subs');
        if (subMenuOthers !== null) {
            subMenuOthers.forEach(submenu => {
                slideUp(submenu);
                arrowDownIco = submenu.parentElement.querySelector('.c-header-menu__list-item-arrow-down-ico');
                arrowDownIco.classList.remove('c-header-menu__list-item-arrow-down-ico--rotate');
            });
        }
        window.setTimeout(() => {
            const subMenu = hasChildren.querySelector('.c-header-menu__menu-subs');
            const displaySubMenu = window.getComputedStyle(subMenu).display;
            if (displaySubMenu == 'none') {
                setSlideDown(subMenu);
                arrowDownIco = subMenu.parentElement.querySelector('.c-header-menu__list-item-arrow-down-ico');
                arrowDownIco.classList.add('c-header-menu__list-item-arrow-down-ico--rotate');
            }
        }, 200);
    }

    function toggleSubMenu(hasChildren) {
        const subMenu = hasChildren.querySelector('.c-header-menu__menu-subs');
        const displaySubMenu = window.getComputedStyle(subMenu).display;
        if (displaySubMenu == 'flex') {
            hideSubMenu(hasChildren);
        } else {
            showSubMenu(hasChildren);
        }
    }

    function enterMenuItem() {
        this.classList.add('c-header-menu__list-item--hover');
        if (this.classList.contains('c-header-menu__list-item--has-children')) {
            showSubMenu(this);
        }
    }

    function leaveMenuItem() {
        this.classList.remove('c-header-menu__list-item--hover');
        if (this.classList.contains('c-header-menu__list-item--has-children')) {
            hideSubMenu(this);
        }
    }

    function menuItemsMouseActive() {
        if (menuItems !== null) {
            menuItems.forEach(menuItem => menuItem.addEventListener('mouseenter', enterMenuItem));
            menuItems.forEach(menuItem => menuItem.addEventListener('mouseleave', leaveMenuItem));
        }
    }

    function menuItemsMouseDeactive() {
        if (menuItems !== null) {
            menuItems.forEach(menuItem => menuItem.removeEventListener('mouseenter', enterMenuItem));
            menuItems.forEach(menuItem => menuItem.removeEventListener('mouseleave', leaveMenuItem));
        }
    }

    function destroyPostsSliders() {
        if (swiperPostsSliders.length > 0) {
            if (newestPostsSliders !== null) {
                newestPostsSliders.forEach(newestPostsSlider => {
                    const swiperWrapper = newestPostsSlider.querySelector('.c-newest-posts-se__slider > .swiper-wrapper');
                    const swiperSlides = newestPostsSlider.querySelectorAll('.c-newest-posts-se__slider > .swiper-wrapper > .swiper-slide');
                    if (swiperWrapper !== null && swiperSlides !== null) {
                        newestPostsSlider.classList.remove('swiper');
                        swiperWrapper.classList.add('row');
                        swiperWrapper.classList.remove('swiper-wrapper');
                        swiperSlides.forEach(swiperSlide => {
                            swiperSlide.classList.add('col-12', 'col-md-4');
                            swiperSlide.classList.remove('swiper-slide');
                        });
                    }
                });
            }

            if (referencesPostsSliders !== null) {
                referencesPostsSliders.forEach(referencesPostsSlider => {
                    const swiperWrapper = referencesPostsSlider.querySelector('.c-references-se__slider > .swiper-wrapper');
                    const swiperSlides = referencesPostsSlider.querySelectorAll('.c-references-se__slider > .swiper-wrapper > .swiper-slide');
                    if (swiperWrapper !== null && swiperSlides !== null) {
                        referencesPostsSlider.classList.remove('swiper');
                        swiperWrapper.classList.add('row');
                        swiperWrapper.classList.remove('swiper-wrapper');

                        swiperSlides.forEach(swiperSlide => {
                            swiperSlide.classList.add('col-12', 'col-md-4');
                            swiperSlide.classList.remove('swiper-slide');
                        });
                    }
                });
            }
            if (similarPostsSliders !== null) {
                similarPostsSliders.forEach(similarPostsSlider => {
                    const swiperWrapper = similarPostsSlider.querySelector('.c-similar-posts-se__slider > .swiper-wrapper');
                    const swiperSlides = similarPostsSlider.querySelectorAll('.c-similar-posts-se__slider > .swiper-wrapper > .swiper-slide');
                    if (swiperWrapper !== null && swiperSlides !== null) {
                        similarPostsSlider.classList.remove('swiper');
                        swiperWrapper.classList.add('row');
                        swiperWrapper.classList.remove('swiper-wrapper');
                        swiperSlides.forEach(swiperSlide => {
                            swiperSlide.classList.add('col-12', 'col-md-4');
                            swiperSlide.classList.remove('swiper-slide');
                        });
                    }
                });
            }

            swiperPostsSliders.forEach(swiperPostsSlider => {
                swiperPostsSlider.destroy('true', 'true');
            });
            swiperPostsSliders = [];
        }
    }
    /*******************************************/
    function initPostsSliders() {
        if (newestPostsSliders !== null) {
            newestPostsSliders.forEach(newestPostsSlider => {
                const swiperWrapper = newestPostsSlider.querySelector('.c-newest-posts-se__slider > .row');
                const swiperSlides = newestPostsSlider.querySelectorAll('.c-newest-posts-se__slider > .row > .col-12');
                if (swiperWrapper !== null && swiperSlides !== null) {
                    newestPostsSlider.classList.add('swiper');
                    swiperWrapper.classList.add('swiper-wrapper');
                    swiperWrapper.classList.remove('row');
                    swiperSlides.forEach(swiperSlide => {
                        swiperSlide.classList.add('swiper-slide');
                        swiperSlide.classList.remove('col-12', 'col-md-4');
                    });
                    const localConfig = configPostsSliders;
                    if (paginationPostsSliders) {
                        const pagination = newestPostsSlider.querySelector('.swiper-pagination');
                        localConfig['pagination'] = {
                            el: pagination,
                            type: 'bullets',
                            clickable: true
                        };
                    }
                    if (navigationPostsSliders) {
                        const prevButton = newestPostsSlider.querySelector('.swiper-button-prev');
                        const nextButton = newestPostsSlider.querySelector('.swiper-button-next');
                        localConfig['navigation'] = {
                            nextEl: nextButton,
                            prevEl: prevButton
                        };
                    }
                    const slider = new Swiper(newestPostsSlider, localConfig);
                    swiperPostsSliders.push(slider);
                }
            });
        }

        if (referencesPostsSliders !== null) {
            referencesPostsSliders.forEach(referencesPostsSlider => {
                const swiperWrapper = referencesPostsSlider.querySelector('.c-references-se__slider > .row');
                const swiperSlides = referencesPostsSlider.querySelectorAll('.c-references-se__slider > .row > .col-12');
                if (swiperWrapper !== null && swiperSlides !== null) {
                    referencesPostsSlider.classList.add('swiper');
                    swiperWrapper.classList.add('swiper-wrapper');
                    swiperWrapper.classList.remove('row');
                    swiperSlides.forEach(swiperSlide => {
                        swiperSlide.classList.add('swiper-slide');
                        swiperSlide.classList.remove('col-12', 'col-md-4');
                    });
                    const localConfig = configPostsSliders;
                    if (paginationPostsSliders) {
                        const pagination = referencesPostsSlider.querySelector('.swiper-pagination');
                        localConfig['pagination'] = {
                            el: pagination,
                            type: 'bullets',
                            clickable: true
                        };
                    }
                    if (navigationPostsSliders) {
                        const prevButton = referencesPostsSlider.querySelector('.swiper-button-prev');
                        const nextButton = referencesPostsSlider.querySelector('.swiper-button-next');
                        localConfig['navigation'] = {
                            nextEl: nextButton,
                            prevEl: prevButton
                        };
                    }
                    const slider = new Swiper(referencesPostsSlider, localConfig);
                    swiperPostsSliders.push(slider);
                }
            });
        }
        if (similarPostsSliders !== null) {
            similarPostsSliders.forEach(similarPostsSlider => {
                const swiperWrapper = similarPostsSlider.querySelector('.c-similar-posts-se__slider > .row');
                const swiperSlides = similarPostsSlider.querySelectorAll('.c-similar-posts-se__slider > .row > .col-12');
                if (swiperWrapper !== null && swiperSlides !== null) {
                    similarPostsSlider.classList.add('swiper');
                    swiperWrapper.classList.add('swiper-wrapper');
                    swiperWrapper.classList.remove('row');
                    swiperSlides.forEach(swiperSlide => {
                        swiperSlide.classList.add('swiper-slide');
                        swiperSlide.classList.remove('col-12', 'col-md-4');
                    });
                    const localConfig = configPostsSliders;
                    if (paginationPostsSliders) {
                        const pagination = similarPostsSlider.querySelector('.swiper-pagination');
                        localConfig['pagination'] = {
                            el: pagination,
                            type: 'bullets',
                            clickable: true
                        };
                    }
                    if (navigationPostsSliders) {
                        const prevButton = similarPostsSlider.querySelector('.swiper-button-prev');
                        const nextButton = similarPostsSlider.querySelector('.swiper-button-next');
                        localConfig['navigation'] = {
                            nextEl: nextButton,
                            prevEl: prevButton
                        };
                    }
                    const slider = new Swiper(similarPostsSlider, localConfig);
                    swiperPostsSliders.push(slider);
                }
            });
        }
    }
    /*******************************************/
    function screenResize() {
        if (wpadminbar) {
            wpadminbarHeight = wpadminbar.getBoundingClientRect().height;
            html.style.marginTop = wpadminbarHeight + 'px';
        } else {
            wpadminbarHeight = 0;
            html.style.marginTop = wpadminbarHeight + 'px';
        }

        /* TURN OFF MOBILE MENU ON DESKTOP RES */
        if ((menu !== null) && (mqXL.matches || mqCustomXL.matches || mqXXL.matches || mqXXXL.matches)) {
            menuItemsMouseActive();
            if (typeof menuScroll !== 'undefined') {
                menuScroll.destroy();
            }
            //body.classList.remove('disable-scroll');
            menu.classList.remove('c-header-menu--is-active');
            mainHeaderHamburger.classList.remove('c-main-header__hamburger--is-open');
            menu.classList.add('d-flex');
            menu.style.display = 'flex';
        }
        /********************************************/
        /* DESTROY POSTS SLIDERS ON HIGHER VIEWS */
        if (mqLG.matches || mqXL.matches || mqCustomXL || mqXXL || mqXXXL) {
            if (swiperPostsSliders.length > 0) {
                destroyPostsSliders();
            }
        }
        /********************************************/
        /* MAKE POSTS SLIDERS ON MOBILE */
        if (menu !== null && mainHeaderHamburger !== null && mqSM.matches || mqMD.matches || mqLG.matches) {
            menuItemsMouseDeactive();
            menuScroll = OverlayScrollbars(menu, {
                className: 'os-theme-thin-dark',
                scrollbars: {
                    autoHide: "leave"
                },
                overflowBehavior: {
                    x: "hidden",
                    y: "scroll"
                }
            });
            //body.classList.remove('disable-scroll');
            menu.classList.remove('c-header-menu--is-active');
            mainHeaderHamburger.classList.remove('c-main-header__hamburger--is-open');
            menu.classList.remove('d-flex');
            menu.style.display = 'none';
        }
        /********************************************/
        /* MAKE POSTS SLIDERS ON MOBILE */
        if (mqSM.matches || mqMD.matches) {
            if (swiperPostsSliders.length == 0) {
                initPostsSliders();
            }
        }
        /*******************************************/
        if (header !== null) {
            mainHeaderHeight = header.getBoundingClientRect().height;
            body.style.paddingTop = mainHeaderHeight + 'px';
        }
    }
    /*******************************************/
    function initScripts() {
        /* RESIZE FUNCTIONS */
        mqSM.addListener(function(mql) {
            if (mql.matches) {
                screenResize();
            }
        });

        mqMD.addListener(function(mql) {
            if (mql.matches) {
                screenResize();
            }
        });

        mqLG.addListener(function(mql) {
            if (mql.matches) {
                screenResize();
            }
        });

        mqXL.addListener(function(mql) {
            if (mql.matches) {
                screenResize();
            }
        });

        mqCustomXL.addListener(function(mql) {
            if (mql.matches) {
                screenResize();
            }
        });

        mqXXL.addListener(function(mql) {
            if (mql.matches) {
                screenResize();
            }
        });
        mqXXXL.addListener(function(mql) {
            if (mql.matches) {
                screenResize();
            }
        });

        if (header !== null) {
            mainHeaderHeight = header.getBoundingClientRect().height;
        }
        if (wpadminbar !== null) {
            wpadminbarHeight = wpadminbar.getBoundingClientRect().height;
            html.style.marginTop = wpadminbarHeight + 'px';
        } else {
            wpadminbarHeight = 0;
            html.style.marginTop = wpadminbarHeight + 'px';
        }

        if (mainHeaderHamburger !== null) {
            mainHeaderHamburger.addEventListener('click', function() {
                toggleMenu();
            });
        }

        if (!wpadmin) {
            document.querySelectorAll('a[href^="#"]').forEach((trigger) => {
                trigger.onclick = function(e) {
                    e.preventDefault();
                    const hash = e.currentTarget.getAttribute("href").split('#');
                    const scrollTarget = document.querySelector('#' + hash[1]);
                    scrollToSmoothly(scrollTarget.offsetTop - mainHeaderHeight, 1000);
                    if (menu.classList.contains('c-header-menu--is-active')) {
                        toggleMenu();
                    }
                };
            });
        }

        if (menuSection !== null) {
            menuSection.addEventListener('click', (e) => {
                if (e.target.closest('.c-header-menu__list-item--has-children')) {
                    if (e.target.classList.contains('c-header-menu__list-item-link-text')) {
                        return;
                    } else {
                        e.preventDefault();
                        const hasChildren = e.target.closest('.c-header-menu__list-item--has-children');
                        toggleSubMenu(hasChildren);
                    }
                }
            });
        }

        if (tof !== null && navigationList !== null && content !== null) {
            initTOF();
            if (navigationList.children) {
                tof.style.display = "block";
            }
        }

        if (!wpadmin) {
            if (window.location.hash) {
                const hash = window.location.href.split("#")[1];
                const scrollTarget = document.querySelector("#" + hash);
                scrollToSmoothly(scrollTarget.offsetTop - 50, 1000);
            }
        }
    }
    /*******************************************/
    if (!wpadmin) {
        initScripts(); /* INITALIZE SCRIPTS */
        screenResize(); /* ON SCREEN RESIZE ACTIONS */
    } else {
        if (window.acf) {
            window.acf.addAction('render_block_preview', function() {
                html = document.querySelector('html');
                body = document.querySelector('body');
                wpadminbar = document.querySelector('#wpadminbar');
                header = document.querySelector('.c-main-header');
                mainHeaderHamburger = document.querySelector('.c-main-header__hamburger');
                menu = document.querySelector('.c-header-menu');
                menuSection = document.querySelector('.c-header-menu__list');
                menuItems = document.querySelectorAll('.c-header-menu__list-item');
                tof = document.querySelector('.c-tof');
                content = document.querySelectorAll('.c-standard-section__content');
                navigationList = document.querySelector('.c-tof__list');
                newestPostsSliders = document.querySelectorAll('.c-newest-posts-se__slider');
                referencesPostsSliders = document.querySelectorAll('.c-references-se__slider');
                similarPostsSliders = document.querySelectorAll('.c-similar-posts-se__slider');
                initScripts(); /* INITALIZE SCRIPTS */
                screenResize(); /* ON SCREEN RESIZE ACTIONS */
            });
        }
    }
});